<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <el-col :span="4">
        <el-input
          placeholder="手机号码"
          v-model="query.phone"
          @change="getList(1)"
        ></el-input>
      </el-col>

      <el-col :span="4">
        <el-input
          placeholder="姓名"
          v-model="query.userRealName"
          @change="getList(1)"
        ></el-input>
      </el-col>

      <el-col :span="6">
        <el-date-picker
          v-model="dateRange"
          type="datetimerange"
          @change="changeDate"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
        >
        </el-date-picker>
      </el-col>

      <el-col :span="4">
        <el-select placeholder="类型" v-model="query.detailType" clearable>
          <el-option
            v-for="(item, index) in this.const.USER_ASSETS_TYPE"
            :value="item.value"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-col>

      <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>

        <el-button type="primary" @click="doExport">用户资产导出</el-button>
      </el-col>
    </el-row>

    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column label="姓名" prop="userRealName"></el-table-column>
      <el-table-column
        label="手机号"
        prop="phone"
        align="right"
      ></el-table-column>

      <el-table-column label="操作金额" align="right" prop="amount">
        <template #default="scope">
          <span>{{ scope.row.amount / 100 }}</span>
        </template>
      </el-table-column>

      <el-table-column label="操作后金额" align="right" prop="amount">
        <template #default="scope">
          <span>{{ scope.row.after / 100 }}</span>
        </template>
      </el-table-column>

      <el-table-column label="变更类型" prop="detailType">
        <template #default="scope">
          <eb-badge
            :list="this.const.USER_ASSETS_TYPE"
            :target="scope.row.detailType"
          ></eb-badge>
        </template>
      </el-table-column>

      <el-table-column label="原因" prop="expandInfo"></el-table-column>

      <el-table-column label="备注" prop="remark"></el-table-column>

      <el-table-column label="创建时间" prop="createTime">
        <template #default="scope">
          <span>{{
            this.$filter.dateFormat(new Date(scope.row.createTime))
          }}</span>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      layout="total, prev, pager, next"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="getList"
    ></el-pagination>

    <UpdateAssetsModal
      :modalData="modalData"
      :ref="MODAL_KEY.UPDATE_ASSETS_MODAL"
      @submit="doUpdateAssets"
    ></UpdateAssetsModal>
  </div>
</template>


<script>
import {
  fetchUserAssetDetail,
  updateUserAssets,
  exportUserAssetsList,
} from "@/api/batteryMan";

import { mapState } from "vuex";
import UpdateAssetsModal from "./components/UpdateAssetsModal.vue";
import moment from "moment";

export default {
  components: { UpdateAssetsModal },
  computed: mapState({
    agentList: (state) => {
      return state.agent.agentList || { list: [], map: {} };
    },
  }),
  data() {
    // let startTime = moment()
    //   .startOf("day")
    //   .subtract(1, "month").format("x");
    // let endTime = moment().endOf("day").format("x");
    return {
      dateRange: [],
      MODAL_KEY: {
        UPDATE_ASSETS_MODAL: "UPDATE_ASSETS_MODAL",
      },
      
      dataSource: [],
      modalData: {},
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      endTime: -1,
      expire: -1,
      query: {
        // endTime: endTime,
        // startTime: startTime,
      },
      total: 0,
    };
  },

  mounted() {
    let phone = this.$router.currentRoute._value.query.phone;
    if (phone) {
      this.query.phone = phone;
    }
    this.getList(1);
  },

  methods: {
    doExport() {
       if(!this.query.startTime){
        this.$message.error("请选择时间段");
        return;
      }

      exportUserAssetsList(this.query).then(res=>{
        this.util.apiDownLoadFile(res.data,"application/vnd.ms-excel",`${this.query.startTime}-${this.query.endTime}用户资产.xlsx`)
      })
    },

    changeDate(e) {
      console.log(e);
      if (!this.validatenull(e)) {
        this.query.startTime = moment(e[0]).startOf("day").format("YYYY-MM-DD HH:mm:ss");
        this.query.endTime =  moment(e[1]).endOf("day").format("YYYY-MM-DD HH:mm:ss");
        this.dateRange = e;
      } else {
        this.query.startTime = "";
        this.query.endTime = "";
      }
      console.log(this.query,"this.query")
      this.getList(1);
    },

    doUpdateAssets(data) {
      updateUserAssets(data).then((res) => {
        this.getList();
        this.$refs[this.MODAL_KEY.UPDATE_ASSETS_MODAL].closeModal();
      });
    },

    getList(current) {
      if (!this.validatenull(current)) {
        this.pages.pageIndex = current;
      }
      fetchUserAssetDetail({ ...this.query, ...this.pages }).then((res) => {
        let list = res.data.data.records || [];
        this.total = res.data.data.total;
        this.dataSource = list;
      });
    },
  },
};
</script>
